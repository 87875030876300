<template>
  <div
    v-if="!isPageLoading"
    class="ScreeningsUpdateTenderDetails">
    <div class="NormalPageTitle">{{ mixWB('W: Detaljer for udbud') }}</div>
    <CloseButton />

    <TenderDetailsForm
      :isButtonLoading="isButtonLoading"
      :buttonText="next.text"
      @form-submit="onFormSubmit" />
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import TenderDetailsForm from '@/components/Forms/TenderDetailsForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ScreeningsUpdateTenderDetails',
  data() {
    return {
      isPageLoading: true,
      isButtonLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'projectType',
      'noOfBuildings',
      'noOfSelectedUnits',
      'builderFullName',
      'currentScreeningData',
      'currentScreeningLoaded',
    ]),
    next() {
      if (this.currentScreeningData.isStarted) {
        return {
          text: this.mixWB('SAVE'),
          path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.projectType) {
        return {
          text: this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateProjectDetails', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.noOfBuildings && !this.noOfSelectedUnits) {
        return {
          text: this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateBuildings', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.builderFullName && this.builderFullName !== '') {
        return {
          text: this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateBuilder', params: { screeningID: this.currentScreening.id } },
        }
      }
      return {
        text: this.mixWB('SAVE'),
        path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
      }
    },
  },
  methods: {
    onFormSubmit(tenderDetails) {
      this.isButtonLoading = true

      this.$store.dispatch('setTenderDetails', tenderDetails)

      this.$router.push(this.next.path)
    },
  },
  components: {
    CloseButton,
    TenderDetailsForm,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (!this.currentScreeningLoaded) {
        return
      }

      clearInterval(waitForLoad)
      this.isPageLoading = false
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateTenderDetails
    pageWrap()
</style>
