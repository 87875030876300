<template>
  <div
    v-if="!isPageLoading"
    class="TenderDetailsForm">
    <div
      class="CheckboxWrap"
      v-for="item in tenderQuestionsAsArray"
      :key="item.id">
      <Checkbox
        :name="item.id"
        :isSelected="tenderDetails.selectedQuestionIDs.includes(item.id)"
        :text="mixWB(item.wordbook)"
        @checkbox-click="onCheckboxClick" />
    </div>

    <div class="InputListWrap">
      <Textarea
        v-for="(text, index) in tenderDetails.otherTexts"
        :key="text + index"
        :name="index.toString()"
        :value="text"
        :placeholder="mixWB('W: Andet...')"
        backgroundColor="white"
        autoExpand
        @on-update="onFieldUpdate" />
      <div class="ButtonWrap">
        <Button
          :text="mixWB('W: Tilføj et felt mere')"
          color="salmon"
          outlined
          @button-click="onAddFieldClick" />
      </div>
      <span class="Caption">{{ mixWB('W: Bare rolig, tomme felter gemmes ikke') }}</span>
    </div>

    <div class="DateSelectionWrap">
      <div class="DateWrap">
        <span>{{ mixWB('W: Startdato') }}</span>
        <input
          type="date"
          v-model="tenderDetails.startDate" />
      </div>
      <div class="DateWrap">
        <span>{{ mixWB('W: Deadline') }}</span>
        <input
          type="date"
          v-model="tenderDetails.endDate" />
      </div>
    </div>

    <Button
      :text="buttonText"
      :isLoading="isButtonLoading"
      @button-click="onSaveButtonClick" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from '@/components/FormElements/Checkbox.vue'
import Button from '@/components/Buttons/Button.vue'
import Textarea from '@/components/FormElements/Textarea.vue'

export default {
  name: 'TenderDetailsForm',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    isButtonLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isPageLoading: true,
      tenderDetails: {
        otherTexts: [],
        selectedQuestionIDs: [],
        startDate: '',
        endDate: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningLoaded',
      'tenderQuestionsAsArray',
      'currentScreeningTenderDetails',
    ]),
  },
  methods: {
    onCheckboxClick(questionID) {
      const questionIndex = this.tenderDetails.selectedQuestionIDs.findIndex(
        (x) => x === questionID,
      )

      if (questionIndex > -1) {
        this.tenderDetails.selectedQuestionIDs.splice(questionIndex, 1)
        return
      }

      this.tenderDetails.selectedQuestionIDs.push(questionID)
    },
    onFieldUpdate({ name, value }) {
      const index = Number(name)
      this.tenderDetails.otherTexts[index] = value
    },
    onAddFieldClick() {
      this.tenderDetails.otherTexts.push('')
    },
    onSaveButtonClick() {
      this.tenderDetails.otherTexts = this.tenderDetails.otherTexts.filter((x) => x !== '')
      this.$emit('form-submit', this.tenderDetails)
    },
    setSavedDataOnLoad() {
      const data = {
        otherTexts: [''],
        selectedQuestionIDs: [],
        startDate: '',
        endDate: '',
      }

      if (!this.currentScreeningTenderDetails) {
        data.selectedQuestionIDs = this.tenderQuestionsAsArray.reduce(
          (prev, item) => {
            if (item.isAutoFilled) {
              prev.push(item.id)
            }
            return prev
          }, [],
        )
        this.tenderDetails = data
        return
      }

      if (this.currentScreeningTenderDetails.otherTexts.length) {
        data.otherTexts = JSON.parse(JSON.stringify(this.currentScreeningTenderDetails.otherTexts))
      }
      if (this.currentScreeningTenderDetails.selectedQuestionIDs.length) {
        data.selectedQuestionIDs = JSON.parse(JSON.stringify(
          this.currentScreeningTenderDetails.selectedQuestionIDs,
        ))
      }
      if (this.currentScreeningTenderDetails.startDate) {
        data.startDate = this.currentScreeningTenderDetails.startDate
      }
      if (this.currentScreeningTenderDetails.endDate) {
        data.endDate = this.currentScreeningTenderDetails.endDate
      }

      this.tenderDetails = data
    },
  },
  components: {
    Checkbox,
    Button,
    Textarea,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (!this.currentScreeningLoaded) {
        return
      }

      clearInterval(waitForLoad)
      this.isPageLoading = false

      this.setSavedDataOnLoad()
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .TenderDetailsForm
    display block

  .InputListWrap
    margin-bottom 20px

  .ButtonWrap
    margin-bottom 5px

  .Caption
    font-style italic
    color $color_grey

  .DateSelectionWrap
    display flex
    justify-content space-between
    margin-bottom 10px
    .DateWrap
      width calc(50% - 5px)
      margin-bottom 10px
      input
        height 40px
        border 1px solid $color_grey_lighter
        padding-left 10px
        padding-right 10px
</style>
